<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item :to="{path:'/my'}">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{name:'MyOrder'}">我的订单</el-breadcrumb-item>
          <el-breadcrumb-item>订单ID:{{sn}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
  export default {
    name:"Breadcrumb",
     data () {
         return {
            
         }
     },
     props:{
       sn:{
         type:String
       }
     },
    created(){
    }
    
  }
</script>
<style lang="less" scoped>
.breadcrumb {
    padding-bottom: 20px;
    padding-top: 20px;
    color: #666666;
    font-size: 14px;
    line-height: 18px;
}
 
</style>
